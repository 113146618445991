/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_BOOKINGS(state, bookings) {
    state.bookings = bookings
  },
  UPDATE_BOOKING(state, booking) {
    const bookingIndex = state.bookings.findIndex((p) => p.id == booking.id)
    Object.assign(state.bookings[bookingIndex], booking)
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.bookings.findIndex((p) => p.id == itemId)
    state.bookings.splice(ItemIndex, 1)
  },
}
