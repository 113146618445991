import { render, staticRenderFns } from "./BookingViewSidebar.vue?vue&type=template&id=3e584838&scoped=true&"
import script from "./BookingViewSidebar.vue?vue&type=script&lang=js&"
export * from "./BookingViewSidebar.vue?vue&type=script&lang=js&"
import style0 from "./BookingViewSidebar.vue?vue&type=style&index=0&id=3e584838&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e584838",
  null
  
)

export default component.exports