/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import bookingAPI from '@/APIs/boBookingAPI'

export default {
  listBookings({ commit }, pageObject) {
    return new Promise((resolve, reject) => {
      let url = '';
      let params = {
        params: {
          page: pageObject.page,
          size: pageObject.size
        }
      };

      bookingAPI.get(url, params)
        .then((response) => {
          commit('SET_BOOKINGS', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  // Update
  updateBooking({ commit }, item) {
    //console.log(JSON.stringify(item.id));
    //console.log(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      bookingAPI.put(`${item.id}/${item.status}`, item )
        .then((response) => {
          commit('UPDATE_BOOKING', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // Borrar
  removeItem({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      bookingAPI.delete(`${itemId}`)
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
