<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="data-list-list-view" class="data-list-container">
    <booking-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />
    <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="bookings">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
        </div>
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ bookings.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : bookings.length }} de {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=5">
              <span>5</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <template slot="thead">
        <vs-th sort-key="project">Proyecto</vs-th>
        <vs-th sort-key="bookedUnit">Unidad</vs-th>
        <vs-th sort-key="client">Cliente</vs-th>
        <vs-th sort-key="bookingDate">Fecha</vs-th>
        <vs-th sort-key="status">Estado</vs-th>
        <vs-th>Acciones</vs-th>
      </template>
      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.project.name }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.bookedUnit }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.client.name }} {{ tr.client.lastName }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.bookingDate | formatDate }}</p>
            </vs-td>
            <vs-td>
              <vs-chip :color="getOrderStatusColor(tr.status)" class="product-order-status">{{translateStatus(tr.status)}}</vs-chip>
              <div v-if="tr.status === 'PAID'">
                <vs-chip :color="getDaysStatusColor(daysDifference(tr.lastUpdate))" class="product-order-status">{{daysDifference(tr.lastUpdate)}} dias de vigencia</vs-chip>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <div v-if="tr.status != 'PAYMENT_REJECTED'">
              <feather-icon icon="ArchiveIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="retrievePdf(tr.id)" />Ver</div>
              <div v-if="tr.status != 'PAYMENT_REJECTED'">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />Editar</div>
            </vs-td>
            <template class="expand-user" slot="expand">
                <div class="vx-row">
                  <!--Datos del Projecto -->
                  <vs-divider color="primary" icon-pack="feather" position="center">
                    Datos del Proyecto
                  </vs-divider>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Nombre:</label>
                    <br>
                    {{tr.project.name}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Cliente:</label>
                    <br>
                    {{ tr.client.name }} {{ tr.client.lastName }}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Unidad:</label>
                    <br>
                    {{tr.bookedUnit}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Adicionales:</label>
                    <br>
                    {{tr.bookedAdditional}} {{tr.bookedWarehouse}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Telefono:</label>
                    <br>
                    {{tr.client.contactData.cellphoneNumber}}
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label>Email:</label>
                    <br>
                    {{tr.client.contactData.email}}
                  </div>
                  <!--Datos Adicionales -->
                  <vs-divider color="primary" icon-pack="feather" position="center">
                    Acciones
                  </vs-divider>
                  <div v-if="tr.status === 'PAID'" class="vx-col md:w-1/3 w-full mt-5">
                    <label>Ver Reserva: </label>
                    <feather-icon icon="ArchiveIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="retrievePdf(tr.id)" />
                  </div>
                  <div v-if="checkPending(tr.status)" class="vx-col md:w-1/3 w-full mt-5">
                    <label>Documento de Cliente: </label>
                    <feather-icon icon="ArchiveIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="retrieveBookingDocumentPdf(tr.id)" />
                  </div>
                  <div class="vx-col md:w-1/3 w-full mt-5">
                    <label>Editar Reserva: </label>
                    <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editData(tr)" />
                  </div>
                  <div v-if="tr.status != 'PAYMENT_REJECTED'"  class="vx-col md:w-1/3 w-full mt-5">
                    <vs-button color="primary" type="border" icon="email" @click="sendBookingEmail(tr.id)" @click.stop="$vs.notify({
                      title:'Email',
                      text:'La Reserva ha sido enviada por Email',
                      color:'primary'})">
                      Reenviar Email
                    </vs-button>
                  </div>
                </div>
            </template>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import EdocService from '@/services/EdocService';
import BookingViewSidebar from '@/views/ui-elements/data-list/BookingViewSidebar.vue'
import moduleBooking from "@/store/booking/moduleBooking.js"

export default {
  components: {
    BookingViewSidebar
  },
  data() {
    return {
      selected: [],
      // products: [],
      itemsPerPage: 5,
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    bookings() {
      return this.$store.state.dataListBooking.bookings
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.bookings.length
    }
  },
  methods: {
    sendBookingEmail(bookingId) {
      //console.log("BookingService generatePdf");
      EdocService.generateBookingPdf(bookingId);
    },
    notify() {
      //console.log("notify");
      this.$vs.notify({
        title: 'Function click',
        text: 'Click on me to execute function',
        color: 'primary',
        fixed: true,
        click: () => {
          // Secondary function
          this.$vs.notify({
            title: 'Secondary function',
            text: 'Executed the function when clicking',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        },
      })
    },
    checkPending(bookingStatus) {
      if (bookingStatus=="PENDING" || bookingStatus=="APPROVED") {
        return true;
      }
      return false;
    },
    retrievePdf(bookingId) {
      var code = process.env.VUE_APP_EDOC_API_ROOT + EdocService.saleofferPdf(bookingId);
      var win = window.open(code, '_blank');
      win.focus();
    },
    retrieveBookingDocumentPdf(bookingId) {
      var code = process.env.VUE_APP_API_ROOT + process.env.VUE_APP_BKNG_API + EdocService.bookingDocumentPdf(bookingId);
      var win = window.open(code, '_blank');
      win.focus();
    },
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    deleteData(id) {
      this.$store.dispatch("dataListBooking/removeItem", id)//.catch(err => { console.error(err) })
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      //console.log(data);
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    daysDifference (date) {
      var date1 = new Date(date);
      var date2 = new Date();
      // The number of milliseconds in one day
      var ONEDAY = 1000 * 60 * 60 * 24;
      // Convert both dates to milliseconds
      var date1_ms = date1.getTime();
      var date2_ms = date2.getTime();
      // Calculate the difference in milliseconds
      var difference_ms = Math.abs(date1_ms - date2_ms);
      // Convert back to days and return
      var value = 10-Math.round(difference_ms/ONEDAY);
      if (value < 0) {
        value = 0;
      }
      return value;
    },
    getDaysStatusColor(days) {
      if (days > 7) return "success"
      if (3 < days && days < 8 ) return "warning"
      if (days < 3) return "danger"
      return "primary"
    },
    getOrderStatusColor(status) {
      if (status == 'AWAITING_PAYMENT') return "warning"
      if (status == 'PAID') return "primary"
      if (status == 'PAYMENT_REJECTED') return "danger"
      if (status == 'APPROVED') return "success"
      if (status == 'REJECTED') return "danger"
      if (status == 'OVERDUED') return "danger"
      if (status == 'PENDING') return "warning"
      return "primary"
    },
    translateStatus(status) {
      if (status == 'AWAITING_PAYMENT') return "En espera de pago"
      if (status == 'PAID') return "Pagada"
      if (status == 'PAYMENT_REJECTED') return "Pago Rechazado"
      if (status == 'APPROVED') return "Aprobada"
      if (status == 'REJECTED') return "Rechazada"
      if (status == 'OVERDUED') return "Vencida"
      if (status == 'PENDING') return "Pendiente"
      return "Indeterminado"
    },
    getPopularityColor(num) {
      if (num > 90) return "success"
      if (num > 70) return "primary"
      if (num >= 50) return "warning"
      if (num < 50) return "danger"
      return "primary"
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    }
  },
  created() {
    if (!moduleBooking.isRegistered) {
      this.$store.registerModule('dataListBooking', moduleBooking)
      moduleBooking.isRegistered = true
    }
    let pageObject = {
      page: 0,
      size: 10
    };

    this.$store.dispatch("dataListBooking/listBookings", pageObject)
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;
        .vs-table--search-input {
          width: 100%;
        }
      }
    }
    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }
    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;
        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }
    .product-name {
      max-width: 23rem;
    }
    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      >span {
        display: flex;
        flex-grow: 1;
      }
      .vs-table--search {
        padding-top: 0;
        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;
          &+i {
            left: 1rem;
          }
          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;
      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }
    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;
        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }
    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
